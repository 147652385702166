
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html {
  font-family: Roboto, sans-serif;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  font-size: 22;
  color: #18181B;
  font-family: Roboto, sans-serif;
}

a {
  font-size: 22;
  color: #18181B;
  font-family: Roboto, sans-serif;
}

h1 {
  font-size: 48px;
  color: #18181B;
  font-family: Roboto, sans-serif;
}

p {
  font-size: 14px;
  color: #5A5B61;
  font-family: Roboto, sans-serif;
}

ul {
  font-size: 14px;
  color: #5A5B61;
  font-family: Roboto, sans-serif;
}

input, select {
  font-size: 16px;
  padding-left:10px;
  font-family: Roboto, sans-serif;
}
iframe {
  border-width: 0px;
  height: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 16px;

}
